import React, { useState } from "react";
import slugify from "react-slugify";

import Form from "../../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import NumberInput from "../../../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { STORE_API } from "../../../../../../../Utilities/APIs/APIs";
import { VStack } from "@chakra-ui/react";
import SingleImageInput from "../../../../../../Partials/Layouts/Forms/FormInputs/SingleImageInput/SingleImageInput";
import BannerImageInput from "../../../../../../Partials/Layouts/Forms/FormInputs/BannerImageInput/BannerImageInput";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useEffect } from "react";

import Select from "react-select";
import TimeInput from "../../../../../../Partials/Layouts/Forms/TimeInput/TimeInput";

function CreateStore({ setShowCreateForm, setShowModal, triggerFetch }) {
  const axiosInstance = useAxiosInstance();
  const [value, setValue] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [image, setImage] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [isAPIBusy, setIsAPIBusy] = useState();
  const [type, setType] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      !name ||
      !email ||
      !lat ||
      !lng ||
      !mobile ||
      !city ||
      !street ||
      !state ||
      !zipCode ||
      !image ||
      !bannerImage ||
      !startTime ||
      !endTime
    ) {
      toast.error("Please add all field");
      return;
    }
    setIsAPIBusy(true);

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("slug", slugify(name));
    itemData.append("email", email);
    itemData.append("mobile", mobile);
    itemData.append("city", city);
    itemData.append("state", state);
    itemData.append("street", street);
    itemData.append("zipCode", zipCode);
    itemData.append("bannerImage", bannerImage);
    itemData.append("image", image);
    itemData.append("coordinates", `${lat}, ${lng}`);
    itemData.append("type", type);
    itemData.append("startTime", startTime);
    itemData.append("endTime", endTime);
    itemData.append("timezone", "America/New_York");

    console.log("itemData", itemData);
    const response = await axiosInstance.post(
      STORE_API + "add-store/",
      itemData
    );

    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
    setIsAPIBusy(false);
  }

  const handleMarkerDrag = (newLat, newLng) => {
    setLat(newLat);
    setLng(newLng);
  };

  useEffect(() => {
    const getLatLngAsync = async () => {
      if (!value || !value.label) {
        console.error("Value or label is undefined");
        return;
      }

      try {
        const results = await geocodeByAddress(value.label);
        console.log("Geocode results:", results);
        const latLng = await getLatLng(results[0]);
        console.log("Successfully got latitude and longitude", latLng);
        setLat(latLng.lat);
        setLng(latLng.lng);
      } catch (error) {
        console.error("Error getting latitude and longitude", error);
      }
    };

    getLatLngAsync();
  }, [value]);

  const options = [
    { value: "Independent Reseller", label: "Independent Reseller" },
    { value: "Sneaker Store", label: "Sneaker Store" },
  ];

  console.log(type);

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <VStack spacing={"3"} align={"stretch"}>
        <p className="input_field_label caption bold">
          Please enter store location to show on map
        </p>
        {/* <Map
          zoom={15}
          center={{ lat, lng }}
          marker={{ lat, lng }}
          mcClass="post-map"
          handleClick={(event) => {

            setLat(event.latLng.lat());
            setLng(event.latLng.lng());
          }}

          handleMarkerDrag={handleMarkerDrag}
        /> */}
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          onLoadFailed={(error) =>
            console.error("Could not inject Google script", error)
          }
          selectProps={{
            value,
            onChange: setValue,
            placeholder: "Search for your location",
          }}
          autocompletionRequest={{
            componentRestrictions: {
              country: ["us"],
            },
          }}
        />

        <SingleImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowCreateImage
        >
          Logo
        </SingleImageInput>

        <div>
          <p className="input_field_label caption bold">Select Store Type</p>
          <Select
            options={options}
            onChange={(e) => setType(e.value)}
            placeholder={"Store Type"}
            styles={{
              placeholder: (provided) => ({
                ...provided,
                fontSize: "13px",
              }),
            }}
          />
        </div>
        <ShortTextInput
          value={name}
          // placeholder={`Enter Store Name`}
          label={"Enter Store Name"}
          setState={setName}
        />

        <EmailInput
          value={email}
          // placeholder={`Enter Email`}
          label={"Enter Email"}
          setState={setEmail}
        />
        <NumberInput
          value={mobile}
          // placeholder={`Enter Mobile`}
          label={"Enter Store Mobile Number"}
          setState={setMobile}
        />
        {/* <p>Pin Your Location </p>
        <Map/> */}
        {/* <GoogleMaps/> */}
        <ShortTextInput
          value={state}
          // placeholder={`Enter State`}
          label={"Enter State"}
          setState={setState}
        />
        <ShortTextInput
          value={city}
          // placeholder={`Enter City/Country`}
          label={"Enter City"}
          setState={setCity}
        />

        <ShortTextInput
          value={street}
          // placeholder={`Enter Street`}
          label={"Enter Street"}
          setState={setStreet}
        />
        <ShortTextInput
          value={zipCode}
          // placeholder={`Enter ZipCode`}
          label={"Zip Code"}
          setState={setZipCode}
        />
        <TimeInput
          value={startTime}
          // placeholder={`Enter Start Time`}
          label={"Store opening Time"}
          setState={setStartTime}
        />
        <TimeInput
          label={"Store closing Time"}
          value={endTime}
          // placeholder={`Enter Start Time`}
          setState={setEndTime}
        />
        {/* <SelectTimeZone value={timezone} setState={setTimezone} /> */}

        <BannerImageInput
          fieldId={2}
          state={bannerImage}
          setState={setBannerImage}
          allowCreateImage
          children={"Banner Image (ratio must be 1060px * 500px)"}
        />

        <FormSubmitButton isLoading={isAPIBusy} text="Create Store" />
      </VStack>
    </Form>
  );
}

export default CreateStore;
