import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_API } from "../../../../Utilities/APIs/APIs";
import AsyncSelect from "react-select/async";

export default function SelectProduct({ setRecommendedItems, storeId }) {
  const [options, setOptions] = useState([]);
  const axiosInstance = useAxiosInstance();
  const [hasMore, setHasMore] = useState(false);

  // const
  // Load initial options when the component mounts

  useEffect(() => {
    const loadInitialOptions = async () => {
      try {
        const response = await axiosInstance.get(
          PRODUCT_API + `getSellerStoreProducts/${storeId}`,
          {
            params: {
              page: 1,
              limit: 20,
              storeId: storeId,
            },
          }
        );
        const initialOptions = response.data.map((product) => ({
          label: product.product.name,
          value: product.product._id,
        }));
        setOptions(initialOptions);
      } catch (err) {
        console.error(err);
      }
    };
    loadInitialOptions();
  }, [axiosInstance, storeId]);

  const loadOptions = async (inputValue, callback) => {
    console.log(inputValue.length);
    if (inputValue.length) {
      try {
        const { data } = await axiosInstance.get(
          PRODUCT_API +
            `getSellerRecommendation/${storeId}?search=${inputValue}`
        );
        const newOptions = data.map((product) => ({
          label: product?.product.name,
          value: product?.product._id,
        }));
        setHasMore(data.length >= 10);
        setOptions(newOptions);
        callback(newOptions);
      } catch (err) {
        callback(options);
      }
    } else {
      callback(options);
    }
  };

  return (
    <div>
      <AsyncSelect
        // loadOptions={loadOptions}
        loadOptions={(inputValue, callback) =>
          loadOptions(inputValue, callback)
        }
        isMulti
        cacheOptions
        noOptionsMessage={() => "No product found"}
        closeMenuOnSelect={false}
        defaultOptions={options}
        placeholder="Recommended products from your store (not archived)"
        // options={options}
        onChange={(e) => setRecommendedItems(e.map((id) => id.value))}
      />
    </div>
  );
}
