function TotalOrderPayments({ totalOrderValue }) {
  console.log("order Value is", totalOrderValue);
  return (
    <div className="card">
      <p>Total Order Payment</p>
      <h3>$ {totalOrderValue.toLocaleString()}</h3>
    </div>
  );
}

export default TotalOrderPayments;
