import React from "react";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import CreateProduct from "../../CreateProduct/CreateProduct";
import UpdateProduct from "../../UpdateProduct";
import ArchiveItem from "../../../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import ViewProduct from "../../ViewProduct";
import { PRODUCT_API } from "../../../../../../Utilities/APIs/APIs";

export default function ProductModal({
  type,
  showModal,
  handleClose,
  showCreateForm,
  showUpdateForm,
  showDeleteSection,
  showViewSection,
  setShowCreateForm,
  setShowUpdateForm,
  setShowModal,
  setShowDeleteSection,
  targetID,
  triggerFetch,
  seller,
  active,
  storeId,
  toggleFetch,
  sellerStoreProductId,
}) {
  return (
    <>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Product`) ||
            (showUpdateForm && `Update Product `)
          }
        >
          {showCreateForm && (
            <CreateProduct
              type={type}
              seller={seller}
              storeId={storeId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {/* {showCreateForm && type === "apparel" && (
            <CreateApparel
              type={type}
              seller={seller}
              storeId={storeId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )} */}
          {showUpdateForm && (
            <UpdateProduct
              type={type}
              toggleFetch={toggleFetch}
              seller={seller}
              targetID={targetID}
              storeId={storeId}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              sellerStoreProductId={sellerStoreProductId}
            />
          )}
          {console.log("showDeleteSection", showDeleteSection)}

          {showDeleteSection && (
            <ArchiveItem
              api={PRODUCT_API + "activeSellerStoreProduct/"}
              targetID={targetID}
              seller={seller}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={active}
            />

            // <DeleteItem
            //   api={PRODUCT_API + 'deleteProduct/'}
            //   targetID={targetID}
            //   setShowModal={setShowModal}
            //   setShowDeleteSection={setShowDeleteSection}
            //   triggerFetch={triggerFetch}
            // />
          )}
        </Modal>
      )}
      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Product`}
        >
          <ViewProduct targetID={targetID} seller={seller} />
        </Modal>
      )}
    </>
  );
}
