import SelectGender from "../CreateProduct/components/SelectGender/SelectGender";
import Stock from "./components/Stock/Stock";
import SelectPrice from "./components/SelectPrice/SelectPrice";
import SelectSize from "./components/SelectSize/SelectSize";
import "../AddNewSize/AddNewField.css";
import ShowSizeAndGender from "./components/ShowSizeAndGender";
import SelectCondition from "./components/SelectCondition";

function UpdateSizeAndGenderPrice({
  sellerStoreProductSizes,
  gender,
  setGender,
  size,
  setSize,
  price,
  setPrice,
  stock,
  setStock,
  productCondition,
  setProductCondition,
}) {
  return (
    <div>
      <p className="field_label">Update Existing Sizes</p>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {sellerStoreProductSizes?.map((item, index) => (
          <div style={{ display: "flex", gap: "1rem" }} key={item._id}>
            {/* <SelectGender setState={setGender} value={item.gender} />
             */}
            <ShowSizeAndGender item={item} />

            <SelectCondition
              productCondition={
                productCondition[index]?.productCondition ||
                item.productCondition
              }
              setProductCondition={(newProductCondition) => {
                const updatedProductCondition = [...productCondition];

                updatedProductCondition[index] = {
                  ...item,
                  productCondition: newProductCondition,
                };
                console.log(updatedProductCondition, "updatedProductCondition");
                setProductCondition(updatedProductCondition);
              }}
            />

            <SelectPrice
              price={price[index]?.retailCost || item.retailCost}
              setPrice={(newPrice) => {
                const updatedPrices = [...price];
                updatedPrices[index] = { ...item, retailCost: newPrice };
                setPrice(updatedPrices);
              }}
            />
            <Stock
              stock={stock[index]?.stock || item.stock}
              setStock={(newStock) => {
                const updatedStock = [...stock];
                updatedStock[index] = { ...item, stock: newStock };
                setStock(updatedStock);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default UpdateSizeAndGenderPrice;
