import web3 from "web3";
import { useEffect, useState } from "react";
import { VStack } from "@chakra-ui/react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";

function EditPaymentCardInfo({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
  toggleFetch,
}) {
  // {bankName,accountHolderName,accountNumber,routingNumber,mobile,email,bankAddress

  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [isAPIBusy, setIsAPIBusy] = useState();
  const axiosInstance = useAxiosInstance();
  const [account, setAccount] = useState(null);
  const [branchName, setBranchName] = useState("");

  useEffect(() => {
    async function fetchAndsetCardInfo() {
      const { data } = await axiosInstance.get("cardInfo/" + targetID);
      setBankName(data.bankName);
      setAccountHolderName(data.accountHolderName);
      setAccountNumber(data.accountNumber);
      setRoutingNumber(data.routingNumber);
      setMobile(data.mobile);
      setEmail(data.email);
      setBankAddress(data.bankAddress);
      setAccount(data.walletAddress);
    }
    fetchAndsetCardInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsAPIBusy(true);

    const itemData = {
      bankName,
      accountHolderName,
      accountNumber,
      routingNumber,
      mobile,
      email,
      bankAddress,
      walletAddress: account,
    };

    const response = await axiosInstance.patch(
      "cardInfo/" + targetID,
      itemData
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
    setIsAPIBusy(false);
  }

  const connectWallet = async () => {
    console.log("trying to connect wallet");
    if (web3) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <VStack spacing={"3"} align={"stretch"}>
        <ShortTextInput
          value={accountHolderName}
          placeholder={`Enter Account Holder Name`}
          setState={setAccountHolderName}
        />
        <ShortTextInput
          value={bankName}
          placeholder={`Enter Bank Name`}
          setState={setBankName}
        />
        <NumberInput
          value={accountNumber}
          placeholder={`Enter Account Number`}
          setState={setAccountNumber}
        />
        <NumberInput
          value={routingNumber}
          placeholder={`Enter Routing Number`}
          setState={setRoutingNumber}
        />
        <ShortTextInput
          value={bankAddress}
          placeholder={`Enter Bank Address`}
          setState={setBankAddress}
        />
        <ShortTextInput
          value={branchName}
          placeholder={`Enter Branch Address`}
          setState={setBranchName}
        />
        <EmailInput
          value={email}
          placeholder={`Enter Email`}
          setState={setEmail}
        />
        <NumberInput
          value={mobile}
          placeholder={`Enter Mobile`}
          setState={setMobile}
        />
        {account ? (
          <p>Wallet Address: {account}</p>
        ) : (
          <button
            onClick={connectWallet}
            type="button"
            style={{
              backgroundColor: "#F5841F",
              color: "black",
              padding: "8px",
              width: "100%",
              fontSize: "14px",
              fontWeight: "500",
              border: "none",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              marginTop: "10px",
            }}
          >
            Connect Crypto Wallet -&gt;
          </button>
        )}
        <FormSubmitButton isLoading={isAPIBusy} text="Update Payment Info" />
      </VStack>
    </Form>
  );
}

export default EditPaymentCardInfo;
