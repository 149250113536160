function TotalPaidPayment({ totalPaidPayment }) {
  return (
    <div className="card">
      <p>Total Paid Payment</p>
      <h3>$ {totalPaidPayment.toLocaleString()}</h3>
    </div>
  );
}

export default TotalPaidPayment;
