import Select from "react-select";

export default function SelectCondition({
  productCondition,
  setProductCondition,
}) {
  const options = [
    { value: "New", label: "New" },
    { value: "Pre-owned", label: "Pre-owned" },
  ];

  console.log(productCondition, "c-------------");

  return (
    <div style={{ width: "100%" }}>
      <Select
        options={options}
        value={options.find((option) => option.value === productCondition)}
        isSearchable={false}
        onChange={(e) => setProductCondition(e.value)}
      />
    </div>
  );
}
