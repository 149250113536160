import CRUDButton from "../CRUDButton";

import "./ArchiveButton.css";

function ArchiveButton({
  children,
  setShowModal,
  setShowDeleteSection,
  targetID,
  setTargetID,
  isArchive,
  setArchive,
}) {
  return (
    <CRUDButton
      dataTip="Delete"
      dataFor="Delete Item"
      handleClick={() => {
        setShowModal(true);
        setShowDeleteSection(true);
        setTargetID(targetID);
        setArchive(isArchive);
      }}
      deleteButton
    >
      {children}
    </CRUDButton>
  );
}

export default ArchiveButton;
