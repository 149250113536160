import AsyncSelect from "react-select/async";
import useFetchSize from "../../Hooks/useFetchSize";
import { useState } from "react";

export default function SelectSize({
  size,
  type,
  gender,
  setSize,
  setSelectedSize,
}) {
  const { sizes, loadOptions } = useFetchSize({ type, gender });

  return (
    <div style={{ width: "100%" }}>
      {gender !== "" && (
        <>
          <AsyncSelect
            placeholder={"Choose Sizes"}
            isMulti={false}
            defaultOptions={sizes}
            value={size}
            onChange={(e) => {
              setSize(e);
              setSelectedSize(e.value);
            }}
            loadOptions={(inputValue, callback) =>
              loadOptions(inputValue, callback)
            }
          />
        </>
      )}
    </div>
  );
}
