import React, { useContext, useState } from "react";
import Form from "../../../../Partials/Layouts/Forms/Form";
import { VStack } from "@chakra-ui/react";
import EmailInput from "../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import PasswordInput from "../../../../Partials/Layouts/Forms/FormInputs/PasswordInput/PasswordInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { AppContext } from "../../../../../AppContext/AppContext";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {
  const navigate = useNavigate();
  const { apiState, seller, login } = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    const sellerData = {
      email: email.toLowerCase(),
      password: password,
    };

    login(sellerData);
    if (seller) {
      navigate("/");
    }
  }
  return (
    <Form onSubmit={handleSubmit}>
      <VStack spacing="3" align={"stretch"}>
        <EmailInput
          value={email}
          placeholder={"Please Enter your Email"}
          setState={setEmail}
        />
        <PasswordInput
          value={password}
          placeholder={"Please Enter your Password"}
          setState={setPassword}
        />
        <FormSubmitButton
          text={"Sign In"}
          isLoading={apiState.type === "login" && apiState.status === "pending"}
        />
      </VStack>
    </Form>
  );
}
