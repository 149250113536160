import { useState } from "react";
import UploadThumb from "../../../../../../Assets/img_upload.svg";

import "./BannerImageInput.css";
import { toast } from "react-toastify";

function BannerImageInput({
  fieldId,
  state,
  setState,
  allowCreateImage,
  allowUpdateImage,
  children,
}) {
  //for image preview
  const [imagePreview, setImagePreview] = useState({ preview: "" });
  const handleChange = (e) => {
    setState(e.target.files[0]);
    setImagePreview({
      preview: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const allowedWidth = 1060;
    const allowedHeight = 500;

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        console.log("Image aspect ratio", img);
        img.onload = () => {
          const width = img.width;
          const height = img.height;

          if (width === allowedWidth && height === allowedHeight) {
            console.log("Image aspect ratio is valid", img);
            setState(file);
            setImagePreview({
              preview: URL.createObjectURL(event.target.files[0]),
            });
          } else {
            toast.error(
              "Invalid image aspect ratio. Please upload an image with 1060x500 aspect ratio."
            );
            setState(null);
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <label className="upload_file_wrapper" htmlFor={`fileInput${fieldId}`}>
        <p
          className="input_field_label"
          style={{ fontSize: "14px", color: "gray" }}
        >
          {children}
        </p>
        <div className="banner_preview_image">
          {allowUpdateImage && state && (
            <img
              src={
                imagePreview.preview
                  ? imagePreview.preview
                  : process.env.REACT_APP_SPACES_URL + state
              }
              alt="default thumb"
            />
          )}

          {allowCreateImage && (
            <img
              src={imagePreview.preview ? imagePreview.preview : UploadThumb}
              alt="default thumb"
            />
          )}
        </div>
      </label>

      <input
        id={`fileInput${fieldId}`}
        type="file"
        className="file_upload_input"
        onChange={handleImageUpload}
      />
    </>
  );
}

export default BannerImageInput;
