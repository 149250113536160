import { Button } from "@chakra-ui/react";
import "./FormSubmitButton.css";
import { FiArrowRight, FiArrowUpRight } from "react-icons/fi";

function FormSubmitButton({
  text,
  isLoading,
  onClick = () => {},
  loadingText = "",
  disabled,
}) {
  return (
    // <div className='btn_wrapper'>
    //   <button type='submit' className='submit w_100'>
    //     {text}
    //   </button>

    <Button
      rightIcon={<FiArrowRight />}
      bg="#322A5D"
      color={"white"}
      _hover={{
        bg: "#322A5D50",
      }}
      fontSize="small"
      paddingY="18px"
      type="submit"
      w="full"
      isLoading={isLoading}
      onClick={onClick}
      isDisabled={disabled}
      loadingText={loadingText}
    >
      {text}
    </Button>
    // </div>
  );
}

export default FormSubmitButton;
