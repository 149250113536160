function TotalPayablePayment({ totalPayablePayment }) {
  return (
    <div className="card">
      <p>Remaining Payment:</p>
      <h3>$ {totalPayablePayment.toLocaleString()}</h3>
    </div>
  );
}

export default TotalPayablePayment;
