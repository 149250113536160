import React from "react";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ImageCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { IoArrowForward, IoChevronDown } from "react-icons/io5";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import { Chakra_Styles } from "../../MyStore";
import ArchiveButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";

export default function MyStoreTable({
  stores,
  setShowModal,
  showCreateForm,
  setShowCreateForm,
  setShowUpdateForm,
  setShowDeleteSection,
  setTargetID,
  setArchive,
  navigate,
}) {
  return (
    <CRUDTable>
      <tbody>
        {stores?.map((store) => (
          <CRUDTableRow key={store._id}>
            <ImageCell
              imgSrc={store.image}
              style={{
                height: "40px",
                width: "40px",
              }}
              cellStyle={{ width: "40px" }}
            />
            <ShortTextCell
              text={store.name}
              cellStyle={{ textAlign: "left", fontWeight: "600" }}
            />

            <td>
              <Menu>
                <HStack w={"100%"} justifyContent={"end"}>
                  <Button
                    onClick={() => navigate(`/mystores/orders/${store?._id}`)}
                    rightIcon={<IoArrowForward />}
                    size="md"
                    bg="#322A5D"
                    color={"white"}
                    _hover={{
                      bg: "#5165E2",
                      color: "white",
                    }}
                    _active={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                  >
                    View Orders
                  </Button>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDown />}
                    size="md"
                    bg="#322A5D30"
                    // color='white'
                    _hover={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                    _active={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                  >
                    Actions
                  </MenuButton>
                </HStack>
                <MenuList>
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={store._id}
                    setTargetID={setTargetID}
                  >
                    <Text {...Chakra_Styles.actionMenuItem}>
                      Edit Store Info
                    </Text>
                  </EditButton>
                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={store._id}
                    isArchive={store.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  >
                    <Text {...Chakra_Styles.actionMenuItem}>
                      {store.isArchive ? "Unarchive Store" : "Archive Store"}
                    </Text>
                  </ArchiveButton>
                  <MenuDivider />

                  <CRUDButton
                    dataTip="Product"
                    dataFor="Product Item"
                    handleClick={() =>
                      navigate("/mystores/products/" + store._id)
                    }
                  >
                    <Text {...Chakra_Styles.actionMenuItem}>Show Products</Text>
                  </CRUDButton>
                </MenuList>
              </Menu>
            </td>
          </CRUDTableRow>
        ))}
      </tbody>
    </CRUDTable>
  );
}
